<template>
  <div style="background-color: #FAFAFA;padding-top: 25px">
    <div style="background-color: white">
      <div class="center">
        <!--列表-->
        <div style="padding-bottom: 100px">
          <div class="mp-main">
            <List item-layout="vertical" :split="false" :loading="listLoading">
              <ListItem v-for="(item,index) in mediaReportList" :key="index" style="padding: 0">
                <router-link :to="{path: 'mediaReportDetail',query:{'id':item.id}}" target="_blank" class="mp-list">
                  <img :src="item.imageUrl" class="mpl-img" />
                  <div class="mpl-des">
                    <div class="mpl-des-title">
                      <div class="mpl-des-title-text ellipse-1">{{ item.title }}</div>
                      <div class="mpl-des-title-time">{{ item.createdTime }}</div>
                    </div>
                    <div class="mpl-des-int ellipse-2">
                      {{item.introduction}}
                    </div>
                  </div>
                </router-link>
              </ListItem>
            </List>
          </div>

          <Page @on-change="onPageChange" :current="pageNum" :page-size="10" :total="totalNum" show-elevator
                style="margin-top: 20px;float: right"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getMediaReportList} from "../../plugins/api/mediaReportApi";

  export default {
    name: "MediaReport",
    data() {
      return {
        mediaReportList: [],//媒体报告列表
        pageNum: 1,//当前页
        totalNum: 0,//总个数
        listLoading: true,//列表是否在加载中
      }
    },
    mounted() {
      this.getMediaReportList();
    },
    methods: {
      async getMediaReportList() {
        this.listLoading = true;
        const params = {
          pageSize: 10,
          pageNum: this.pageNum
        }
        const json = await getMediaReportList(params).catch(() => this.listLoading = false);
        if (json && json.code === 0) {
          if (json.result && json.result.list && json.result.list.length > 0) {
            this.mediaReportList = json.result.list;
            this.totalNum = json.result.total;
          }
        }
        this.listLoading = false
      },
      onPageChange(pageNum) {
        this.pageNum = pageNum;
        this.getMediaReportList();
      },

    },

  }
</script>

<style scoped lang="scss">

  .center {
    width: 1200px;
    margin: 0 auto;
  }
  .mp-main {
    background-color: #FFFFFF;
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .mp-list {
    height: 125px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px dashed rgba(229, 232, 237, 1);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .mpl-img {
    width: 120px;
    height: 90px;
    border-radius: 5px;
  }

  .mpl-des {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    flex: 1;
    overflow: hidden;
  }

  .mpl-des-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .mpl-des-title-text {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC, serif;
    font-weight: 500;
    color: #333333;
  }

  .mpl-des-title-time {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC, serif;
    font-weight: 400;
    color: #999999;
    margin-left: 15px;
    flex-shrink: 0;
  }

  .mpl-des-int{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC,serif;
    font-weight: 400;
    color: #999999;
    margin-top: 15px;
  }
</style>
